<template>
  <div>
    <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
      <h2 class="text-lg font-medium mr-auto">開店精靈</h2>
    </div>
    <!-- BEGIN: HTML Table Data -->
    <div class="intro-y box p-5 mt-5">
      <div class="overflow-x-auto scrollbar-hidden mt-2">
        <stepper
          ref="stepper"
          v-bind="stepperOptions"
          @next="next"
          @complete="complete"
        >
          <template #step1="{ node, nodes }">
            <vxe-form
              ref="step1Form"
              v-bind="step1FormOptions"
              :data="node.data"
              @reset="reset(node)"
              @submit="submit(node, nodes)"
            >
            </vxe-form>
          </template>

          <template #step2="{ node, nodes }">
            <vxe-form
              ref="step2Form"
              v-bind="step2FormOptions"
              :data="node.data"
              @reset="reset(node)"
              @submit="submit(node, nodes)"
            >
              <template #step2-column-principal-id="{ data }">
                <select-box v-bind="step2PersonSelectorOptions" v-model="data.PrincipalId" />
              </template>
            </vxe-form>
          </template>

          <template #step3="{ node, nodes }">
            <vxe-form
              ref="step3Form"
              v-bind="step3FormOptions"
              :data="node.data"
              @reset="reset(node)"
              @submit="submit(node, nodes)"
            >
              <template #step3-column-merchant-id="{ data }">
                <select-box v-bind="step3MerchantSelectorOptions" v-model="data.MerchantId" />
              </template>
            </vxe-form>
          </template>

          <template #step4="{ node, nodes }">
            <vxe-form
              ref="step4Form"
              v-bind="step4FormOptions"
              :data="node.data"
              @reset="reset(node)"
              @submit="submit(node, nodes)"
            >
              <template #step4-column-contract-id="{ data }">
                <select-box v-bind="step4ContractSelectorOptions" v-model="data.ContractId" />
              </template>
              <template #step4-column-store-category-id="{ data }">
                <select-box v-bind="step4StoreCategorySelectorOptions" v-model="data.StoreCategoryId" />
              </template>
              <template #step4-column-picture="{ data }">
                <file-uploader
                  ref="step4PictureUploader"
                  id="step4-picture-uri"
                  style="min-width: 150px; max-width: 150px; min-height: 150px; max-height: 180px;"
                  mode="image"
                  :modelValue="data.Picture?.Uri"
                  :action="uploadAction"
                  :autoUpload="true"
                  :limitedWidth="300"
                  :limitedHeight="300"
                  @update:modelValue="(value) => { if (data.Picture) data.Picture.Uri = value; else data.Picture = { Uri: value }; }"
                  @filter="uploaderFilter"
                />
              </template>
            </vxe-form>
          </template>
        </stepper>
      </div>
    </div>
    <!-- END: HTML Table Data -->
  </div>
  <br />
</template>

<script lang="ts">
import CloudFun, { Condition, defineComponent, Operator, ref } from '@cloudfun/core';
import Stepper, { IStepperNode, IStepperOptions } from '@/cloudfun/components/Stepper.vue';
import { VxeFormInstance, VxeFormProps } from 'vxe-table';
import SelectBox, { SelectBoxOptions } from '@/cloudfun/components/SelectBox.vue'
import FileUploader from "@/cloudfun/components/FileUploader.vue";
import { VueUploadItem } from 'vue-upload-component';

export default defineComponent({
  components: {
    Stepper,
    SelectBox,
    FileUploader,
  },
  setup () {
    const model = CloudFun.current?.model
    const stepper = ref<any>();

    // #region step 1

    const step1Form = ref<VxeFormInstance>();
    const step1FormOptions: VxeFormProps = {
      titleWidth: 100,
      titleAlign: "right",
      span: 3,
      items: [
        { field: 'Name', title: '名稱', span: 12, itemRender: { name: '$input', props: { placeholder: '請輸入文字' } } },
        { field: 'Nickname', title: '暱稱', span: 12, itemRender: { name: '$input', props: { placeholder: '暱稱' } } },
        { field: 'PersonalId', title: '身分證字號', span: 12, itemRender: { name: '$input', props: { placeholder: '請輸入文字' } } },
        { field: 'Sex', title: '性別', span: 12, itemRender: { name: '$select', props: { placeholder: '請選擇' }, options: model ? Object.values(model.enums.Sex).map(e => { return { label: e.Name, value: e.Value } }) : [] } },
        { field: 'BirthDate', title: '出生日期', span: 12, itemRender: { name: '$input', props: { type: 'date', placeholder: '請輸入文字' } } },
        { field: 'Gender', title: '性向', span: 12, itemRender: { name: '$select', props: { placeholder: '請選擇' }, options: model ? Object.values(model.enums.Gender).map(e => { return { label: e.Name, value: e.Value } }) : [] } },
        { field: 'MobilePhone', title: '行動電話', span: 12, itemRender: { name: '$input', props: { placeholder: '請輸入電話號碼' } } },
        { field: 'Phone', title: '市內電話', span: 12, itemRender: { name: '$input', props: { placeholder: '請輸入電話號碼' } } },
        { field: 'Email', title: 'Email', span: 24, itemRender: { name: '$input', props: { placeholder: '請輸入Email' } } },
        { field: 'Address.Line', title: '地址', span: 24, itemRender: { name: '$input', props: { placeholder: '請輸入地址' } } },
        { field: 'Remark', title: '備註', span: 24, itemRender: { name: '$input', props: { placeholder: '請輸入文字' } } },
      ],
      rules: {
        Name: [{ required: true }],
        PersonalId: [{
          validator: (params) => {
            if (!params.itemValue) return;
            const sex: boolean | undefined = [0, 1].includes(params.data.Sex) ? params.data.Sex : undefined;
            const birthDate: Date | undefined = params.data.BirthDate || undefined;
            return CloudFun.utils.validator.validatePersonalId(params.itemValue, sex, birthDate);
          }
        }],
        Sex: [{
          validator: params => params.$form.validateField('PersonalId')
        }],
        BirthDate: [{
          validator: params => params.$form.validateField('PersonalId')
        }],
        MobilePhone: [{
          validator: (params) => {
            if (!params.data.MobilePhone && !params.data.Email) return new Error('行動電話或Email至少填寫一項');
            if (!params.itemValue) return;
            return CloudFun.utils.validator.validatePhoneNumber(params.itemValue, 'TW');
          }
        }],
        Email: [{
          validator: (params) => {
            if (!params.data.MobilePhone && !params.data.Email) return new Error('行動電話或Email至少填寫一項');
            if (!params.itemValue) return;
            return CloudFun.utils.validator.validateEmail(params.itemValue);
          }
        }],
        Phone: [{
          validator: (params) => {
            if (!params.itemValue) return;
            return CloudFun.utils.validator.validatePhoneNumber(params.itemValue, 'TW');
          }
        }],
      },
    };

    // #endregion
    // #region step 2

    const step2Form = ref<VxeFormInstance>();
    const step2FormOptions: VxeFormProps = {
      titleWidth: 100,
      titleAlign: "right",
      span: 3,
      items: [
        { field: 'Number', title: '編號', span: 12, itemRender: { name: '$input', props: { placeholder: '系統自動產生', disabled: true } } },
        { field: 'Status', title: '狀態', span: 12, itemRender: { name: '$select', options: model ? Object.values(model.enums.UserStatus).map(e => { return { label: e.Name, value: e.Value } }) : [] } },
        { field: 'Name', title: '名稱', span: 12, itemRender: { name: '$input', props: { placeholder: '請輸入文字' } } },
        { field: 'IsIndependent', title: '獨立設計師', span: 12, itemRender: { name: '$select', props: { placeholder: '請選擇' }, options: [{ label: '是', value: true }, { label: '否', value: false }] } },
        { field: 'PrincipalId', title: '負責人', span: 12, slots: { default: 'step2-column-principal-id' } },
        { field: 'TaxType', title: '稅別', span: 12, itemRender: { name: '$select', options: model ? Object.values(model.enums.TaxType).map(e => { return { label: e.Name, value: e.Value } }) : [] } },
        { field: 'SettleDay', title: '結算日', span: 12, itemRender: { name: '$input', props: { type: 'number', min: 1, max: 28, placeholder: '請輸入1~28的數字' } } },
        { field: 'PaymentType', title: '付款方式', span: 12, itemRender: { name: '$select', options: model ? Object.values(model.enums.PaymentType).map(e => { return { label: e.Name, value: e.Value } }) : [] } },
        { field: 'InvoiceTitle', title: '發票抬頭', span: 12, itemRender: { name: '$input', props: { placeholder: '請輸入統編' } } },
        { field: 'TaxId', title: '統一編號', span: 12, itemRender: { name: '$input', props: { placeholder: '請輸入文字' } } },
        { field: 'InvoiceAddress.Line', title: '發票地址', span: 24, itemRender: { name: '$input', props: { placeholder: '請輸入地址' } } },
        { field: 'Phone', title: '電話號碼', span: 12, itemRender: { name: '$input', props: { placeholder: '請輸入電話號碼' } } },
        { field: 'Fax', title: '傳真號碼', span: 12, itemRender: { name: '$input', props: { placeholder: '請輸入電話號碼' } } },
        { field: 'Email', title: 'Email', span: 24, itemRender: { name: '$input', props: { placeholder: '請輸入Email' } } },
        { field: 'Address.Line', title: '地址', span: 24, itemRender: { name: '$input', props: { placeholder: '請輸入地址' } } },
      ],
      rules: {
        Name: [{ required: true }],
        PrincipalId: [{ required: true }],
        SettleDay: [{ required: true }],
        InvoiceTitle: [{ required: true }],
        TaxId: [{
          required: true,
          validator: (params) => {
            if (!params.itemValue) return new Error();
            if (params.itemValue && !new RegExp("^[0-9]{8}$").test(params.itemValue)) return new Error('統一編號格式錯誤');
          }
        }],
        Phone: [{
          validator: (params) => {
            if (!params.itemValue) return;
            return CloudFun.utils.validator.validatePhoneNumber(params.itemValue, 'TW');
          }
        }],
        Fax: [{
          validator: (params) => {
            if (!params.itemValue) return;
            return CloudFun.utils.validator.validatePhoneNumber(params.itemValue, 'TW');
          }
        }],
        Email: [{
          required: true,
          validator: (params) => {
            if (!params.itemValue) return new Error('Email為必填');
            return CloudFun.utils.validator.validateEmail(params.itemValue);
          }
        }],
      },
    };

    const step2PersonSelectorOptions: SelectBoxOptions = {
      rowId: "Id",
      transfer: true,
      placeholder: "選擇人物",
      textField: "Name",
      valueField: "Id",
      columns: [
        { field: "Name", title: "名稱", showHeaderOverflow: true, showOverflow: true, sortable: true },
        { field: "MobilePhone", title: "行動電話", showHeaderOverflow: true, showOverflow: true, sortable: true },
        { field: "Email", title: "信箱", showHeaderOverflow: true, showOverflow: true, sortable: true },
      ],
      multiselect: false,
      showHeader: true,
      promises: {
        find: (value) => model!.dispatch("person/find", value), // eslint-disable-line
        query: (params) => model!.dispatch("person/query", params), // eslint-disable-line
      },
    };

    // #endregion
    // #region step 3

    const step3Form = ref<VxeFormInstance>();
    const step3FormOptions: VxeFormProps = {
      titleWidth: 100,
      titleAlign: "right",
      span: 3,
      items: [
        { field: 'Number', title: '編號', span: 12, itemRender: { name: '$input', props: { placeholder: '系統自動產生', disabled: true } } },
        { field: 'Status', title: '狀態', span: 12, itemRender: { name: '$select', options: model ? Object.values(model.enums.ContractStatus).map(e => { return { label: e.Name, value: e.Value } }) : [] } },
        { field: 'MerchantId', title: '商家', span: 12, slots: { default: 'step3-column-merchant-id' } },
        { field: 'Type', title: '類型', span: 12, itemRender: { name: '$select', options: model ? Object.values(model.enums.ContractType).map(e => { return { label: e.Name, value: e.Value } }) : [] } },
        { field: 'StartTime', title: '開始時間', span: 12, itemRender: { name: '$input', props: { type: 'date', placeholder: '請輸入日期' } } },
        { field: 'EndTime', title: '結束時間', span: 12, itemRender: { name: '$input', props: { type: 'date', placeholder: '請輸入日期' } } },
        { field: 'Memo', title: '備忘錄', span: 24, itemRender: { name: '$textarea', attrs: { rows: '20' }, props: { resize: 'none', autosize: { minRows: 10 }, placeholder: '請輸入文字' } } },
      ],
      rules: {
        MerchantId: [{ required: true }],
      },
    };

    const step3MerchantSelectorOptions: SelectBoxOptions = {
      rowId: "Id",
      transfer: true,
      placeholder: "選擇商家",
      textField: "Name",
      valueField: "Id",
      columns: [
        { field: "Number", title: "編號", showHeaderOverflow: true, showOverflow: true, sortable: true },
        { field: "Name", title: "名稱", showHeaderOverflow: true, showOverflow: true, sortable: true },
        { field: "Principal.Name", title: "負責人", showHeaderOverflow: true, showOverflow: true, sortable: true }
      ],
      multiselect: false,
      showHeader: true,
      promises: {
        find: (value) => model!.dispatch("merchant/find", value), // eslint-disable-line
        query: (params) => model!.dispatch("merchant/query", params), // eslint-disable-line
      },
    };

    // #endregion
    // #region step4

    const step4PictureUploader = ref<any>({});
    const step4Form = ref<VxeFormInstance>();
    const step4FormOptions: VxeFormProps = {
      titleWidth: 100,
      titleAlign: "right",
      span: 3,
      items: [
        { field: 'Number', title: '編號', span: 12, itemRender: { name: '$input', props: { placeholder: '系統自動產生', disabled: true } } },
        { field: 'ContractId', title: '契約', span: 12, slots: { default: 'step4-column-contract-id' } },
        { field: 'Name', title: '名稱', span: 12, itemRender: { name: '$input', props: { placeholder: '請輸入文字' } } },
        { field: 'StoreCategoryId', title: '營業類別', span: 12, slots: { default: 'step4-column-store-category-id' } },
        { field: 'Email', title: '服務信箱', span: 12, itemRender: { name: '$input', props: { placeholder: '請輸入Email' } } },
        { field: 'IsPhysical', title: '實體店面', span: 12, itemRender: { name: '$select', props: { placeholder: '請選擇', options: [{ label: '是', value: true }, { label: '否', value: false }] } } },
        { field: 'Phone', title: '門市電話', span: 12, itemRender: { name: '$input', props: { placeholder: '請輸入電話號碼' } } },
        { field: 'Address.Line', title: '地址', span: 12, itemRender: { name: '$input', props: { placeholder: '請輸入地址' } } },
        {
          span: 12,
          children: [
            { field: 'ContactPerson', title: '聯絡人', span: 24, itemRender: { name: '$input', props: { placeholder: '請輸入姓名' } } },
            { field: 'ContactPhone', title: '聯絡電話', span: 24, itemRender: { name: '$input', props: { placeholder: '請輸入電話號碼' } } },
            { field: 'Latitude', title: '緯度', span: 24, itemRender: { name: '$input', props: { type: 'float', placeholder: '請選擇', digits: 6, min: -90, max: 90 } } },
            { field: 'Longitude', title: '經度', span: 24, itemRender: { name: '$input', props: { type: 'float', placeholder: '請選擇', digits: 6, min: -180, max: 180 } } },
          ]
        },
        { field: 'Picture.Uri', title: '圖片', span: 12, slots: { default: 'step4-column-picture' } },
      ],
      rules: {
        ContractId: [{ required: true }],
        StoreCategoryId: [{ required: true }],
        Name: [{ required: true }],
        Phone: [{
          validator: (params) => {
            if (!params.itemValue) return;
            return CloudFun.utils.validator.validatePhoneNumber(params.itemValue, 'TW');
          }
        }],
        ContactPerson: [{ required: true }],
        ContactPhone: [{
          required: true,
          validator: (params) => {
            if (!params.itemValue) return new Error('聯絡電話為必填');
            return CloudFun.utils.validator.validatePhoneNumber(params.itemValue, 'TW');
          }
        }],
        Email: [{
          validator: (params) => {
            if (!params.itemValue) return;
            return CloudFun.utils.validator.validateEmail(params.itemValue);
          }
        }],
        'Picture.Uri': [{
          required: true,
          validator: (params) => {
            if (!params.itemValue) return new Error('未提供店家圖片');
            if (params.itemValue && typeof params.itemValue === 'string' && !params.itemValue.startsWith('http')) return new Error('圖檔尚未上傳完畢');
          }
        }]
      },
    };

    const step4ContractSelectorOptions: SelectBoxOptions = {
      rowId: "Id",
      transfer: true,
      placeholder: "選擇契約",
      textField: "Number",
      valueField: "Id",
      columns: [
        { field: "Number", title: "編號", showHeaderOverflow: true, showOverflow: true, sortable: true },
        { field: "Merchant.Name", title: "商家", showHeaderOverflow: true, showOverflow: true, sortable: true },
        { field: "Type", title: "類型", showHeaderOverflow: true, showOverflow: true, formatter: ({ cellValue }) => model ? Object.values(model.enums.ContractType).find(e => e.Value === cellValue)?.Name : undefined },
      ],
      multiselect: false,
      showHeader: true,
      promises: {
        find: (value) => model!.dispatch("contract/find", value), // eslint-disable-line
        query: (params) => model!.dispatch("contract/query", params), // eslint-disable-line
      },
    };

    const step4StoreCategorySelectorOptions: SelectBoxOptions = {
      rowId: "Id",
      transfer: true,
      placeholder: "選擇營業類別",
      textField: "Name",
      valueField: "Id",
      columns: [
        { field: "Name", title: "名稱", showHeaderOverflow: true, showOverflow: true, sortable: true, width: "100%" },
      ],
      multiselect: false,
      pagerConfig: { pageSize: 6, autoHidden: true },
      promises: {
        find: (value) => model!.dispatch("serviceCategory/find", value), // eslint-disable-line
        query: (params) => {
          params.condition = new Condition("MerchantId", Operator.IsNull).and("ParentId", Operator.IsNull);
          return model!.dispatch("serviceCategory/query", params); // eslint-disable-line
        },
      },
    };

    // #endregion

    const stepperOptions = ref<IStepperOptions>({
      nodes: [
        {
          step: 1,
          title: '建立個資',
          data: {},
          template: 'step1',
          skipable: true,
          reset: async (node) => { node.data = {} },
          validate: async () => { try { await step1Form.value?.validate(); return true; } catch { return false; } },
        },
        {
          step: 2,
          title: '開設商家',
          data: { IsIndependent: false, TaxType: 2, PaymentType: 3, Status: 10 },
          template: 'step2',
          skipable: true,
          reset: async (node) => { node.data = { IsIndependent: false, TaxType: 2, PaymentType: 3, Status: 10 } },
          validate: async () => { try { await step2Form.value?.validate(); return true; } catch { return false; } },
        },
        {
          step: 3,
          title: '簽訂契約',
          data: { Type: 0, Status: 1 },
          template: 'step3',
          skipable: true,
          reset: async (node) => { node.data = { Type: 0, Status: 10 } },
          validate: async () => { try { await step3Form.value?.validate(); return true; } catch { return false; } },
        },
        {
          step: 4,
          title: '開設店家',
          data: {},
          template: 'step4',
          reset: async (node) => { node.data = {} },
          validate: async () => { try { await step4Form.value?.validate(); return true; } catch { return false; } },
        },
      ],
      height: 660,
    });

    return {
      stepper,
      stepperOptions,
      step1Form,
      step1FormOptions,
      step2Form,
      step2FormOptions,
      step2PersonSelectorOptions,
      step3Form,
      step3FormOptions,
      step3MerchantSelectorOptions,
      step4Form,
      step4FormOptions,
      step4ContractSelectorOptions,
      step4StoreCategorySelectorOptions,
      step4PictureUploader,
      uploadAction: `${process.env.VUE_APP_STORAGE_SERVICE}/api/files`,
    }
  },
  methods: {
    async uploaderFilter (current: VueUploadItem, original: VueUploadItem, prevent: any) {
      if (!current || !current.name) return prevent()
      if (!/\.(png|gif|jpg|jpeg)$/i.test(current.name)) {
        alert('未支援此種圖片格式')
        return prevent()
      }
    },
    next(params: { from: IStepperNode, to: IStepperNode, nodes: IStepperNode[] }, callback: any) {
      let success = false;
      switch (params.from.step) {
        case 1:
          try {
            if (params.from.data.Id) {
              if (params.to.data) params.to.data = {}
              params.to.data.PrincipalId = params.from.data.Id;
            } else if (this.step1Form) this.step1Form.dispatchEvent('submit', undefined, new Event('submit'));
            success = true;
          } catch { }
          if (success) callback();
          break;
        case 2:
          try {
            if (params.from.data.Id) {
              if (params.to.data) params.to.data = {}
              params.to.data.MerchantId = params.from.data.Id;
            } else if (this.step2Form) this.step2Form.dispatchEvent('submit', undefined, new Event('submit'));
            success = true;
          } catch { }
          if (success) callback();
          break;
        case 3:
          try {
            if (params.from.data.Id) {
              if (params.to.data) params.to.data = {}
              params.to.data.ContractId = params.from.data.Id;
            } else if (this.step3Form) this.step3Form.dispatchEvent('submit', undefined, new Event('submit'));
            success = true;
          } catch { }
          if (success) callback();
          break;
        case 4:
          try {
            if (this.step4Form) this.step4Form.dispatchEvent('submit', undefined, new Event('submit'));
            success = true;
          } catch { }
          if (success) callback();
          break;
      }
    },
    complete(_: IStepperNode[], callback: any) {
      let success = false;
      try {
        if (this.step4Form) this.step4Form.dispatchEvent('submit', undefined, new Event('submit'));
        success = true;
      } catch { }
      if (success) callback();
    },
    submit(node: IStepperNode, nodes: IStepperNode[]) {
      switch (node.step) {
        case 1:
          this.$model.dispatch(`person/${node.data.Id ? 'update' : 'insert'}`, node.data).then(
            success => {
              node.data = success;
              if (!nodes[1].data) nodes[1].data = {};
              nodes[1].data.PrincipalId = success.Id;
            },
            failure => {
              console.log('failure: ', failure);
              this.$send('error', failure.message || failure);
              this.stepper.jump(1, true);
            }
          )
          break;
        case 2:
          this.$model.dispatch(`merchant/${node.data.Id ? 'update' : 'insert'}`, node.data).then(
            success => {
              node.data = success;
              if (!nodes[2].data) nodes[2].data = {};
              nodes[2].data.MerchantId = success.Id;
            },
            failure => {
              this.$send('error', failure.message || failure);
              this.stepper.jump(2, true);
            }
          )
          break;
        case 3:
          this.$model.dispatch(`contract/${node.data.Id ? 'update' : 'insert'}`, node.data).then(
            success => {
              node.data = success;
              if (!nodes[3].data) nodes[3].data = {};
              nodes[3].data.ContractId = success.Id;
            },
            failure => {
              this.$send('error', failure.message || failure);
              this.stepper.jump(3, true);
            }
          )
          break;
        case 4:
          this.$model.dispatch('store/insert', node.data).then(
            success => {
              node.data = success;
            },
            failure => {
              this.$send('error', failure.message || failure);
              this.stepper.jump(4, true);
            }
          )
          break;
      }
    },
  }
});
</script>
